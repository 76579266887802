






import {Vue, Component} from 'vue-property-decorator';
import { forceRefreshRType, forceRefreshAgence, forceRefreshChantier, forceRefreshFiche, forceRefreshAbsences } from '@/api/data';
import { to } from '@/utils';

@Component({})
export default class extends Vue {
    private async refreshData() {
        await to(forceRefreshRType());
        await to(forceRefreshAgence());
        await to(forceRefreshChantier());
        await to(forceRefreshFiche());
        await to(forceRefreshAbsences());
    }
}
